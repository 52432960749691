import { inject, Injectable } from '@angular/core';
import { LoggedInStaff } from './auth.interface';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { baseUrl, loginUrl } from '../../classes/general';
import { environment } from '../../../environments/environment';

const STAFF_STORAGE_KEY =  environment.STAFF_STORAGE_KEY //"loggedInStaff";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  
	_http = inject(HttpClient);
	storeLoggedInStaffDetails(staff: any) {
		localStorage.setItem(STAFF_STORAGE_KEY, JSON.stringify(staff));
	}

	removeLoggedInStaffDetails() {
		localStorage.removeItem(STAFF_STORAGE_KEY);
	}

	getLoggedInStaff(): LoggedInStaff | null {
		const storedItem = localStorage.getItem(STAFF_STORAGE_KEY);
		if (!storedItem) return null;
		return JSON.parse(storedItem) as any;
	}


	login(data: any) {
		return this._http.post<any>(`${loginUrl}/auth/admin/login`, data).pipe(
			tap((res) => this.storeLoggedInStaffDetails(res?.data)),
		);
	}

}
